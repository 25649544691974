.search-form {
  @apply bg-white ml-3;

  .qi-input_wrapper {
    width: 20rem;
  }

  .qi-input {
    @apply mb-0;

    padding: 4px;

    &_text {
      width: 100%;
    }

    .qi-input_wrapper {
      @apply ml-3;
    }

    .icon-wrapper {
      padding: 3px 0 0 5px;

      &.clickable {
        &:hover {
          background-color: #eee;

          .icon {
            fill: #333;
          }
        }
      }
    }
  }
}

.search-form-container {
  @apply relative;

  z-index: 1;

  .icon-wrapper.back-icon {
    @apply bg-white;

    border: 1px solid #c5d2e0;
    border-radius: 3px;
    margin-left: 10px;
    padding: 5px 10px;

    &:hover {
      background-color: #eee;

      .icon {
        fill: #333;
      }
    }
  }

  .advanced-search {
    @apply absolute py-3 bg-white border shadow shadow-lg;

    border-radius: 5px;
    top: -8px;
    left: 50px;
    width: 618px;

    &_header {
      @apply px-5;

      &_title {
        @apply text-lg font-semibold;

        width: calc(100% - 20px);
      }

      .icon-wrapper {
        border-radius: 50%;
        cursor: pointer;
        height: 30px;
        padding: 5px;
        width: 30px;

        &:hover {
          background-color: #eee;

          .icon {
            fill: #333;
          }
        }
      }
    }

    &_container {
      @apply mt-3 px-5;

      overflow: auto;
    }

    &_button-group {
      @apply flex items-center justify-center py-3;

      .qi-btn {
        @apply mx-2;
      }
    }

    .search-by-filter {
      @apply truncate;

      background-color: #eee;
      border-radius: 3px;
      color: #666;
      font-weight: normal;
      margin-left: 10px;
      max-width: calc(100% - 156px);
      padding: 2px 10px;
    }
  }
}
