.roles-form-list {
  .qi-list-view {
    .feature-name-column {
      width: calc(100% - 500px);
    }
    .read-column, .update-column, .create-column, .delete-column {
      @apply text-center;

      width: 125px;

      input {
        @apply mr-2;
      }
    }
  }
}
