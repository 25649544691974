.no-data-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 74vh;
  font-family: Arial, sans-serif;
  text-align: center;
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}
