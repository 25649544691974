.clients {
  .qi-list-view {
    &list_item {
    }

    &_column {
      &.id {
        width: 90px;
      }

      &.backends,
      &.device,
      &.vehicle {
        @apply text-center;

        width: 110px;
      }

      &.geolocation-plan {
        width: 160px;
      }
    }
  }
}

/**Date & Action column width 240px fixed**/

.client-admin {
  &.qi-input {
    margin: 0;
    padding: 0;
    .qi-input_error_message {
      right: 5px;
    }
  }
}
