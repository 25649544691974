.application-summary {
  @apply bg-white;

  &_header {
    @apply px-6 py-4;

    &_title {
      @apply flex text-blue-500 capitalize font-bold;

      align-items: center;

      .icon-wrapper {
        @apply mr-3 p-3;

        background-color: #d8ecff;
        border-radius: 50%;

        .icon {
          fill: #0767d0;
        }
      }
    }
  }
}

.applications-oparations {
  @apply bg-white flex px-6 pb-6;

  &_title {
    @apply flex items-center capitalize text-blue-500 font-bold bg-slate-50 px-6 py-4;

    .icon-wrapper {
      @apply rounded-full mr-3 p-3;

      border: 2px solid #d8ecff;

      .icon {
        fill: #0767d0;
      }
    }

    &:hover {
      .icon-wrapper {
        @apply bg-white;
      }
    }
  }

  .devices {
    @apply border-t border-b border-l;
    width: 20%;

    .applications-oparations_list_item {
      width: 100%;
    }
  }

  .vehicles {
    @apply border;

    width: calc(50% - 2px);

    .applications-oparations_list_item {
      width: 33.33%;
    }
  }

  .others {
    @apply border-t border-b border-r;

    width: 30%;

    .applications-oparations_list_item {
      width: 50%;
    }
  }

  .group {
    @apply border-t border-b border-r;
    width: 15%;

    .applications-oparations_list_item {
      width: 100%;
    }
  }

  &_list {
    @apply p-4 flex flex-wrap;

    &_item {
      @apply px-4 py-2;

      transition: all 0.35s ease;
      width: 50%;

      &:hover {
        @apply bg-sky-50;
      }
    }

    &_link {
      @apply flex flex-col;

      color: #717a85;
      transition: all 0.3s ease;

      &:hover {
        color: #0767d0;
      }

      &_label {
      }

      &_count {
        color: #1a2934;
        font-size: 24px;
      }
    }
  }
}

.dashboard-misc-block {
  width: 30%;

  &_item {
    @apply border ml-6;

    width: 30%;

    .applications-oparations_list_item {
      width: 100%;
    }
  }
}
