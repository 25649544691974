.qi-input {
  @apply flex px-4 py-3 mb-4 border rounded;

  border-color: #c5d2e0;
  transition: all 0.3s ease;

  &_clear {
    @apply bg-blue-500 hover:bg-blue-700 text-white font-bold px-2 rounded;
    cursor: pointer;
  }

  &:hover {
    background-color: #eff6fd;
    border-color: #0767d0;

    // .qi-input {
    //   @apply bg-white;
    // }
  }

  &:focus-visible,
  &:focus {
    outline: 1px solid #0767d0;
  }

  &_label {
    @apply w-40 font-semibold mr-2;
  }

  &_wrapper {
    @apply grow relative;

    min-height: 24px;

    & > div {
      height: 100%;
    }
  }

  &_item {
    @apply flex;
  }

  &_text {
    @apply border-0;

    background-color: transparent;
    height: 100%;

    &:focus {
      outline: 0;
    }
  }

  &.credentials {
    .qi-input_wrapper > div,
    .qi-input_text {
      height: auto;
    }
  }

  &.error {
    border: 1px solid #f96868;

    .password-show-hide {
      right: 25px;
    }

    input {
      padding-right: 30px;
    }
  }

  &_warning {
    @apply absolute justify-end items-center;
    right: 30px;
    top: 0px;
    width: 12em;
    line-height: 0.95em;
    font-size: 0.8rem;
    height: 2em;

    &_message {
      color: $warning;
    }
  }

  &_error {
    @apply absolute;

    color: #f96868;
    right: 0;
    top: 4px;

    .icon {
      fill: #f96868;
    }

    &_message {
      @apply absolute bg-white;

      border-color: #ededed;
      border-radius: 3px;
      border-style: solid;
      border-width: 1px 0 0 1px;
      box-shadow: 7px 4px 8px #ddd;
      display: none;
      font-size: 13px;
      padding: 3px 10px;
      right: 35px;
      top: -6px;
      white-space: nowrap;
      z-index: 999;

      &::after {
        @apply absolute;

        border-color: transparent transparent transparent #fff;
        border-style: solid;
        border-width: 6px 0 6px 10px;
        content: " ";
        display: inline-block;
        right: -9px;
        top: 10px;
      }
    }

    &:hover {
      .qi-input_error_message {
        display: inline-block;
      }
    }
  }

  &.disabled {
    background-color: $disabledFieldBackgroudColor;
    border-color: $disabledFieldBorderColor;

    .qi-input_label {
      color: $disabledFieldInputLabelColor;
    }

    &:hover {
      background-color: $disabledFieldBackgroudColor;
      border-color: $disabledFieldBorderColor;
    }
  }
}

.qi-list-view_list_item:hover .qi-input_error {
  .qi-input_error_message {
    right: 30px;
  }

  .icon {
    fill: #f96868;
  }
}

.qi-form-element {
  .qi-custom-select_dropdown.show {
    bottom: auto;
    top: 46px;
  }

  .qi-custom-select_dropdown_list {
    max-height: 120px;
  }

  &:nth-last-child(2) {
    .qi-custom-select_dropdown.show {
      bottom: 46px;
      top: auto;
    }
  }

  &:last-child {
    .qi-custom-select_dropdown.show {
      bottom: 46px;
      top: auto;
    }
  }

  &:first-child {
    .qi-custom-select_dropdown.show {
      bottom: auto;
      top: 46px;
    }
  }

  &:nth-child(2) {
    .qi-custom-select_dropdown.show {
      bottom: auto;
      top: 46px;
    }
  }
}
