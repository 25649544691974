.static {
  @apply text-slate-600;

  background-color: #fdfdfd;
  /*background-image: linear-gradient(45deg,rgba(212,0,232,0.85) 20%,rgba(0,186,145,0.93) 80%),url(https://www.synox.io/wp-content/uploads/2021/02/Synox-Nos-solutions-IOT-2.jpeg) !important;*/

  p {
    @apply mb-3;
  }

  .site-header {
    @apply flex bg-white items-center;

    border-bottom: 1px solid #eee;
    padding: 5px;

    .brand {
      @apply m-2;
    }
  }

  .main-container-wrapper {
    height: calc(100vh - 94px);
    overflow: auto;
  }

  .site-banner {
    height: 30%;
    position: relative;
    overflow: hidden;

    &_image {
      position: absolute;
      width: 100%;
    }

    &_title {
      background-color: rgba(0, 0, 0, 0.45);
      border: 1px solid #b39143;
      color: #fff;
      font-size: 22px;
      left: calc(50% - 190px);
      padding: 10px 0;
      position: absolute;
      top: calc(50% - 25px);
      text-align: center;
      width: 350px;
    }
  }

  .main-container {
    @apply pt-6;

    margin: 0 auto;
    width: 1024px;

    &_title {
      @apply mb-4 text-blue-800;

      font-size: 24px;
    }
  }

  .information-section {
    @apply mt-12 pb-6 flex justify-between;

    &_title {
      @apply mb-3 text-blue-800;

      font-size: 18px;
    }

    &_graphics {
      @apply mr-3 bg-white p-1;

      border: 1px solid #eee;
      width: 40%;

      img {
        width: 100%;
      }
    }

    &_data {
      width: calc(60% - 25px);
    }

    &.reverse {
      flex-direction: row-reverse;
    }
  }

  .site-footer {
    @apply bg-white pt-2 pb-2 text-center;

    border-top: 1px solid #eee;
    color: #777;
    font-size: 12px;

    p {
      margin-bottom: 0;
    }
  }
}