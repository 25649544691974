.mapLegend {
  position: absolute;
  z-index: 99;
  background-color: rgba(0, 60, 136, 0.3);
  color: white;
  padding: 2px;
  border-radius: 5px;
  width: 300px;
  font-size: 0.8rem;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
  bottom: 60px;
  right: 15px;
  .legend {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .legendPoint {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    border-radius: 50%;
    &--very_slow {
      background-color: #ed1c24;
    }
    &--slow {
      background-color: #330000;
    }
    &--medium {
      background-color: #ff7814;
    }
    &--fast {
      background-color: #800000;
    }
  }
}
