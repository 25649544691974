.qi-custom-select {
  &_dropdown {
    li.system-default {
      @apply bg-indigo-100;

      &:hover {
        @apply bg-indigo-200;
      }
    }
  }
}
