.qi-list-view.device-manufacturers {
  .name {
    width: 250px;
  }

  .device-models {
    width: 250px;
  }

  .device {
    width: 100px;
  }

  .client {
    width: 200px;
  }
}