.qi-chip-select {
  @apply flex;

  @extend .qi-input;
  
  &_chip-wrapper {
    @apply w-full;

    align-items: center;
  }

  &_chip {
    @apply inline-block mb-3;
  }

  &_label {
    @apply w-40 font-semibold;
  }

  &_input {
    @apply bg-white border rounded px-2 py-1 mr-1;

    &:focus-visible, &:focus {
      outline: none;
    }

    &_confirm {
      @apply bg-blue-300 px-2 py-1 rounded cursor-pointer;
    }
  }

  &_confirm-yes, &_confirm-no {
    @apply ml-2;
  }

  &_confirm-yes {
    @apply bg-orange-300 px-2 py-1 rounded;
  }

  &_confirm-no {
    @apply bg-blue-300 px-2 py-1 rounded mr-3;
  }

  &_chip {
    &_name {
      @apply rounded whitespace-nowrap bg-blue-100 px-3 py-2 align-middle cursor-pointer;
    }
  }

  &_close {
    @apply align-middle cursor-pointer inline-block;

    background-color: #f38c8c;
    border-radius: 0 5px 5px 0;
    margin: 0 0 0 -2px;
    height: 36px;
    width: 36px;
  }

  &_input-group {
    
    &_text {
      
    }
  }

  &_error {
    @apply text-red-500 ml-2;
  }
}

.qi-chip_email-container {
  .qi-chip {
    color: #fff;
    background-color: #31c786;
    margin-left: 0;

    .qi-chip_cross {
        background-color: #eee;
        margin-left: 10px;
        padding: 2px;

        .icon {
          fill: #ff5500;
        }
    }
  }
}