.qi-slide-select {
  @apply flex;

  padding-right: 10px;

  .slide-display,
  .slide {
    @apply grid;

    border: 1px solid #e5e7eb;
    border-radius: 3px;
    height: 30px;
    cursor: pointer;
    padding: 0 3px;
    place-content: center;
  }

  .slide:hover {
    background: #0767d0;
    color: #fff;
  }
}
