.qi-pagination-wrapper {
  @apply flex justify-between content-center mt-2;

  align-items: center;
  height: 34px;

  .pagination-nav {
    @apply flex;
  }

  .qi-select.page-count-select {
    @apply mr-2;
  }

  .qi-select_text   {
    width: 65px;
  }

  .perpage-item-and-range {
    @apply flex items-center;
  }
}

.qi-pagination {
  @apply flex;

  align-items: center;

  &_item {
    &_link {
      @apply bg-white border px-2;

      display: inline-block;
      height: 30px;
      margin-left: -1px;
      padding-top: 4px;
      transition: all 0.3s ease;

      .icon {
        height: 20px;
        width: 20px;
      }

      &:hover {
        background-color: #0767d0;
        color: #fff;
        cursor: pointer;
      }
    }

    &.active {
      .qi-pagination_item_link {
        background-color: #0767d0;
        color: #fff;
      }
    }

    &.disabled {
      .qi-pagination_item_link {
        background-color: #f0f5fa;
        color: #717a85;
        cursor: not-allowed;
      }
    }
  }
}
