.qi-list-view.geocoders {
  .name {
    width: 250px;
  }

  .description {
    width: calc(100% - 556px);
  }

  .client-alias-name {
    width: 250px;
  }
}