.login-layout {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.login-form-container {
  align-self: center;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 100px;
  padding: 50px;
  width: 600px;

  &_error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}

.logging-as {
  .qi-custom-select_dropdown {
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
}
