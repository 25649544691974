.window {
  border: 2px solid #bbb;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  &-header {
    border-radius: 4px 4px 0 0;
    padding: 8px 15px 6px;

    h1 {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &-body {
    padding: 5px;
  }
}