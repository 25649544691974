.tracking-trips {
  .tracking-app-main-container {
    display: flex;
    &_block {
      @apply flex;
      flex-direction: column;
    }
  }
  .trips-list-header {
    @apply flex justify-between p-3;

    cursor: pointer;

    .track-info-view {
      @apply flex bg-white rounded justify-around;

      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

      & > span {
        @apply p-1;

        align-self: center;
      }

      & > *.active,
      & > *:hover {
        background-color: #e2e8f0;

        & > .live-icon {
          right: -15px;
        }
      }
    }
  }

  .no-data,
  .qi-list-view {
    height: calc(100vh - 225px);
  }

  .list-view-wrapper {
    @apply p-2;

    max-height: calc(100vh - 180px);
    overflow: auto;
    background-color: #f0f5fa;

    .track-cardlist {
      @apply bg-white p-2 mb-2;

      border-radius: 5px;
      color: #444;
      font-weight: 700;
      max-height: calc(100vh - 300px);

      .stand-card_info {
        width: calc(100% - 160px);
      }

      .stand-card_events {
        @apply ml-3 text-right;

        width: 160px;
      }

      p {
        margin: 5px 0;
      }

      .trip-card,
      .stand-card {
        @apply flex justify-between;
      }
    }
  }

  .end-time-and-location {
    text-align: right;
  }

  .trip-date-picker {
    width: 500px;
  }
  .events-map {
    &.split-down {
      .map-container,
      .qiMap {
        height: calc(100vh - 400px);
      }
    }
  }
  .events-details {
    @apply border bg-white;
    height: 350px;
  }

  .details-block_data-container-wrapper {
    width: 100%;
  }
  .ol-attribution {
    display: none;
  }
}
