.qi-accordion {
  &_item {
  }

  &_item:first-item {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &_header {
    @apply p-2 flex justify-between;

    &_title {
      @apply font-bold text-lg;
    }

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  &_icon {
    transform: rotate(-90deg);

    &.active {
      transform: rotate(90deg);
    }
  }

  &_body {
    
  }
}
