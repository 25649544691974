.geofence-map-container {
  position: relative;

  .coordinate-search {
    background: #fff;
    position: absolute;
    padding: 5px;
    left: 50px;
    top: 8px;
    z-index: 9999;
    .qi-input {
      margin-bottom: 0;
    }
    .icon {
      fill: #fff;
    }
  }
}
.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 8px;
  margin-bottom: 10px;
}
.geofence-map {
  height: calc(100vh - 350px);
  width: 100%;
}
.leaflet-control-attribution {
  display: none;
}
.coordinate-search {
  justify-content: flex-end;
  margin: 5px;
}
.leaflet-right .leaflet-draw-actions li:first-child a {
  background-color: #31c786;
}
.leaflet-right .leaflet-draw-actions li:last-child a {
  background-color: #e7edf5;
  color: #1d4ed8;
}
