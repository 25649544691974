.qi-checkbox {
  @apply flex flex-row-reverse;
  &_label {
    @apply w-40;
  }

  &_input-wrapper {
    @apply mr-2;
  }

  &_input {
  }

  &_error {
  }
}
