.audit-filter {
  .audit-advance-search {
    box-shadow: none;
    max-width: 600px;
    padding: 3px 0;
    width: auto;

    .advanced-search_header_title h5 {
      @apply mr-3;

      font-size: 14px;
      white-space: nowrap;
    }
  }
}

.audit-trail-search {
  .main-container_header_title-and-search-group {
    .qi-custom-select {
      @apply mb-0 ml-3 bg-white;

      padding: 8px 15px;
      width: 500px;
    }

    .search-form-container {
      top: -12px;
    }
  }
}

.change-log-for {
  @apply flex flex-wrap;

  background: #efefef;
  margin-bottom: 5px;
  padding: 10px 15px 0;

  &_item {
    @apply flex mb-2 w-1/2;

    &_label {
      width: 115px;
    }

    &_value {
      width: calc(100% - 150px);
    }
  }
}

.change-log-view-wrapper {
  max-height: calc(100vh - 354px);

  .change-log-view_list {
  }
}

.change-log-view {
  @apply flex bg-slate-100;

  &_list {
    &::after {
      content: " ";
      display: block;
      height: 2px;
    }

    & > li {
      .after {
        background-color: #f3fff6;
      }

      .before {
        background-color: #fff6f5;
      }
    }
  }

  &_item {
    @apply border px-3 py-2;

    overflow-wrap: break-word;
    white-space: pre-wrap;

    &.field {
      width: 150px;
    }

    &.before, &.after {
      width: calc((100% - 150px) / 2);
    }
  }
}

.log-for {
  @apply mb-3;
}

.audit-trail-quick-view {
  max-height: calc(100vh - 280px);

  .qi-list-view_column.expand {
    width: 60px;
  }

  .qi-list-view_list {
    height: auto;
    max-height: calc(100vh - 410px);
    min-height: 200px;

    .icon-expand {
      cursor: pointer;
      padding: 10px;
      transform: rotate(-90deg);
    }

    .expanded {
      border: 1px solid #7190ee;

      .icon-expand {
        transform: rotate(90deg);
      }

      .change-log-view-wrapper {
        background-color: #f7fcff;
        box-shadow: 0 0 5px #b3b3b3 inset;
        max-height: none;
        padding: 10px;

        .change-log-view_list {
          background-color: #fff;
          height: auto;
          max-height: none;
        }
      }
    }
  }

  .qi-list-view_header, .qi-list-view_list_item {
    position: relative;

    .expand {
      position: absolute;
      right: 15px;
      top: 5px;
    }
  }
}
