.window {
  @apply m-2 bg-white;

  position: absolute;

  .window-header {
    background-color: #f5f6fa;
    cursor: move;
    justify-content: space-between;

    h1 {
      font-size: 16px;;
    }
  }
}

.window-body {
  max-height: calc(100vh - 200px);
  min-height: 200px;
  overflow: auto;
}

.minimise,
.maximise {
  background-color: #fff;
  border: 1px solid #9fb5c1;
  border-radius: 2px;
  color: #9fb5c1;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  height: 24px;
  line-height: 20px;
  margin-left: 15px;
  text-align: center;
  width: 24px;

  &:hover {
    background-color: #e0f2fe;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.tab {
  width: 100%;

  .actions {
    margin: auto;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
  }

  .tab-item {
    @apply border-x p-1;
    min-width: 200px;
    background-color: black;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.doppable-container {
  height: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;

  &.draggable-only {
    height: 100vh;
  }
}

.dnd-wrapper {
  width: 100%;
  height: 1px;
  margin: -50% 0 0 0;
  position: static;
}

#olmap {
  position: relative;
}
