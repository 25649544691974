.details-block {
  @apply border bg-white;

  height: 400px;

  &_header {
    background-color: #e7edf5;

    @apply flex justify-between py-2 px-3;

    &_title {
      font-size: 16px;
    }
  }

  &_actions {
    @apply flex;

    &_item {
      @apply px-2;

      cursor: pointer;
    }
  }

  &_section {
    @apply flex py-2 px-3 justify-between;

    height: calc(100% - 100px);

    .trips {
      margin: 0;
      height: 100%;

      .qi-list-view {
        padding-left: 0;
        padding-right: 0;

        &_list {
          height: calc(100vh - 445px);
        }
      }
    }

    &_Map {
      height: calc(100% - 100px);
    }

    &_LiveTrack {
      height: calc(100% - 140px);
    }
  }

  &_summary {
    @apply border p-3;

    width: 270px;

    .item-photo {
      @apply text-center p-3 mb-3;

      border: 5px solid #eee;
      position: relative;
      width: 120px;

      img {
        margin: auto;
      }

      .status {
        border-radius: 50%;
        display: inline-block;
        height: 20px;
        position: absolute;
        width: 20px;
        bottom: -10px;
        right: -10px;
        border: 3px solid #fff;
        &.active {
          background-color: #759d26;
        }
        &.idle {
          background-color: #f0cf20;
        }
        &.offline {
          background-color: #999;
        }
      }
    }
  }

  &_data-container-wrapper {
    width: calc(100% - 280px);

    &.Activities,
    &.Trips,
    &.TrackPoints,
    &.Events {
      width: 100%;
    }
  }

  &_data-container {
    width: 100%;
  }

  &_data {
    @apply border;
  }

  &_other-data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 100%;
    overflow: auto;

    &_item {
      background-color: #f7f7f7;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;
      padding: 5px 15px;
      width: calc(50% - 5px);

      &:hover {
        @apply bg-sky-100;
      }

      &_label,
      &_value {
        word-wrap: break-word;
      }

      &_label {
        width: 60%;
      }

      &_value {
        padding-left: 10px;
        width: 40%;
      }
    }
  }
}

.device-details-window {
  padding: 10px;

  .details-block {
    background-color: whitesmoke;
    margin-right: 15px;
    padding: 15px;
    width: 200px;
  }

  .device-details {
    border: 1px solid #ccc;
    border-bottom: 0;
  }
}

.row {
  border-bottom: 1px solid #ccc;
  display: flex;

  &:first-child {
    background-color: #eee;
    font-weight: 600;
  }

  .col {
    padding: 6px 10px;
    width: 150px;
  }
}
