.qi-list-view_column_key-label,
.tab-slider {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .tab-slider {
    display: inline-block;
  }

  .applications-oparations {
    @apply flex-col;

    .devices,
    .vehicles,
    .others,
    .dashboard-misc-block {
      @apply w-full mb-5;
    }
  }

  .main-container_header_title-and-search-group {
    align-items: flex-start;
    flex-direction: column;

    .search-form {
      margin: 1em 0;

      .qi-input_wrapper {
        width: 100%;
      }
    }
  }

  .qi-list-view_header {
    display: none;
  }

  .qi-list-view_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & > li {
      border: 1px solid #eee;
      margin: 1em 0;
      width: calc(50% - 1em);

      .qi-list-view_list_item {
        align-items: start;

        .qi-list-view_column {
          width: 100% !important;

          &.status {
            text-align: left;
          }

          &.action {
            justify-content: flex-start;
            padding-left: 1em;
          }

          .associated-devices .qi-dropdown_menu,
          .more .qi-dropdown_menu {
            left: auto;
            right: 0;
            top: 22px;
          }

          .more-options-group .qi-dropdown_menu {
            flex-direction: revert;
            left: 2em;
            right: auto;
            top: -10px;
          }
        }
      }

      .qi-list-view_column_key-label {
        display: inline-block;
        margin-right: 0.5em;
      }
    }
  }

  .qi-list-view_header,
  .qi-list-view_list_item {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .user-profile-name > strong {
    display: none;
  }

  .main-container_header {
    align-items: flex-start;
    flex-direction: column;
  }

  .qi-list-view_list li {
    width: 100%;
  }
  .qi-modal,
  .qi-form {
    .qi-custom-select_inputwrapper,
    .qi-input qi-form-element,
    .qi-multiselect-dropdown,
    .qi-input.credentials,
    .qi-input.qi-form-element {
      align-items: normal;
      flex-direction: column;

      .qi-input_label {
        margin-bottom: 1em;
        width: auto;
      }

      .qi-input_wrapper {
        width: auto;
      }
    }
  }
}
