.qi-collapsed {
  @apply bg-white;

  padding: 10px 5px;

  &_icon {
  }
  box-shadow: 3px 0 5px rgba(2, 45, 25, 0.1);
  padding: 10px 5px;
  z-index: 1;

  &_text {
    @apply h-full text-center;

    color: #3288b9;
    font-size: 1.2rem;
    line-height: 1.2rem;
    text-orientation: mixed;
    writing-mode: vertical-lr;
  }

  & + .tracking-app-main-container_block {
    width: calc(100% - 31px);
  }
}

.qi-expanded {
  @extend .qi-collapsed;

  &_header {
    @apply flex justify-around;
    padding: 0px 5px;
    .collapse-icon-wrapper {
      background-color: #f4f4f4;
      border-radius: 4px;
      cursor: pointer;
      padding: 5px 4px;

      &:hover {
        .icon {
          fill: #0767d0;
        }
      }
    }
  }

  &_body {
    @apply h-full;
  }

  & + .tracking-app-main-container_block {
    width: calc(100% - 421px);
  }
}

.qi-collapsed_icon {
  background-color: #f4f4f4;
  cursor: pointer;

  &:hover {
    .icon {
      fill: #0767d0;
    }
  }
}
