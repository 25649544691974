.icon-wrapper {
  display: inline-block;
}

.icon {
  fill: #9fb5c1;
  height: 20px;
  transition: all 0.3s ease;
  width: 20px;

  &.fill-white {
    fill: #ffffff;
  }

  &.rotate-180 {
    transform: rotate(180deg);
  }

  &.rotate-90 {
    transform: rotate(90deg);
  }

  &.rotate-270 {
    transform: rotate(270deg);
  }

  &.rotating {
    animation: rotating 2s linear infinite;
  }

  &.icon-edit {
    &:hover {
      fill: #0767d0;
    }
  }

  &.icon-delete {
    &:hover {
      fill: #d33a32;
    }
  }

  &.engine {
    height: 30px;
    width: 30px;

    &.on {
      fill: #82c708;
    }

    &.off {
      fill: #999;
    }

    &.unknown {
      fill: #ccc;
    }
  }
}
