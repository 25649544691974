.qi-table-custom {
  @apply relative;

  .qi-list-view_column {
    width: 130px;

    &.precision {
      width: fit-content !important;
    }

    &.id {
      overflow: visible !important;
    }
  }

  .qi-input_error {
    right: 1rem;
    top: 0.95rem;
  }

  .add-new {
    @apply mt-2;
  }

  &_form {
    @apply flex justify-between w-full pb-2;

    .qi-select_text {
      @apply w-full;

      margin-left: 0;
    }

    .edit {
      @apply pb-7 relative;

      .error {
        color: $danger;
        left: 1.5em;
        line-height: 0.75rem;
        position: absolute;
        top: 2.5rem;
        white-space: wrap;
      }

      /*.error {
        background-color: #eee;
        border-radius: 3px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
        color: #ff3300;
        left: 1.5em;
        padding: 3px 10px;
        position: absolute;
        top: -10px;

        &::after {
          border-color: #eee transparent transparent;
          border-style: solid;
          border-width: 10px 10px 0;
          content: " ";
          display: inline-block;
          height: 0;
          left: 2em;
          position: absolute;
          width: 0;
        }
      } */
    }

    &_input {
      @apply w-full;

      border-radius: 3px;
      padding: 2px 15px;

      &:focus {
        outline: 1px solid $defultTextLinkColor;
      }
    }
  }

  .action {
    width: 86px;
  }
}