.api-keys-list {
  @apply flex flex-wrap;

  &_card {
    background-color: #fff;
    border: 1px solid #eee;
    margin: 15px 15px 0 0;
    padding: 5px 5px 10px;
    width: calc(25% - 15px);

    &:nth-child(5n - 1) {
      margin-right: 0;
    }

    &_info-list {
      @apply mb-3;

      &_items {
        @apply p-2;

        border-bottom: 1px solid #eee;

        &:first-child {
          background-color: #e7edf5;
        }

        &_label {
          display: inline-block;
          width: 100px;
        }
      }
    }
  }
}
