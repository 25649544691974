.tracking-events {
  .events-list-header {
    @apply p-3;

    cursor: pointer;
    .tracking-app-main-container {
      display: flex;
      &_block {
        @apply flex;
        flex-direction: column;
      }
    }
  }

  .no-data,
  .qi-list-view {
    height: calc(100vh - 225px);
  }

  .list-view-wrapper {
    @apply p-2;
    max-height: calc(100vh - 180px);
    overflow: auto;
    background-color: #f0f5fa;
  }
  .events-date-picker {
    width: 100%;
  }

  .events-map {
    &.split-down {
      .map-container,
      .qiMap {
        height: calc(100vh - 400px);
      }
    }
  }
  //Events Details
  .events-details {
    @apply border bg-white;
    height: 350px;
  }
  .truncate {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
    max-width: 100%;
  }
  .details-block_data-container-wrapper {
    width: 100%;
  }
  .ol-attribution {
    display: none;
  }
}
