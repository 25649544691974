.qi-list-view.backends {
  .name {
    width: 150px;
  }

  .type {
    width: 150px;
  }

  .protocol {
    width: 150px;
  }

  .host {
    width: 150px;
  }

  .path {
    width: 150px;
  }

  .port {
    width: 150px;
  }

  .forwarding {
    width: 150px;
  }
}

.qi-list-view_list {
  &.custom-field_list {
    @apply bg-white;

    border: 1px solid #eee;
    height: auto;

    .qi-list-view_list_item {
      height: auto;

      .icon.icon-delete {
        cursor: pointer;
        height: 20px;
      }
    }
  }

  &.list_expand {
    max-height: 400px;
  }
}
