.profile-header-content {
  @apply flex items-center;

  .page-title {
    @apply mr-4;
  }

  .edit-icon {
    height: 20px;
  }
}

.user-profile-details {
  max-width: 100%;

  &_form-container {
    @apply flex justify-between;

    .profile-form {
      @apply flex justify-between p-6 bg-white;

      width: calc(100% - (30% + 20px));

      &_photo {
        width: 215px;
        margin-right: 50px;
      }

      &_right {
        width: calc(100% - 280px);
      }
      .qi-input_wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &_footer {
    @apply flex justify-center;

    border-top: 1px solid #eee;
    margin-top: 25px;
  }

  .change-password {
    @apply p-6 bg-white;

    width: 30%;
  }
}
