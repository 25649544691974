body {
  background-color: $bodyBackgroundColor;
  color: $defultFontColor;
  font-family: $baseFontFamily;
  font-size: $baseFontSize;
}

a,
.text-link {
  color: $defultTextLinkColor;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

select:focus-visible {
  outline: none;
}

.tab-content {
  @apply px-4 pt-3;

  max-height: calc(100vh - 57px);
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

.page-title {
  @apply font-semibold;

  &.xl {
    @apply text-xl;
  }
}

.page-title-bar {
  @apply flex mb-4;

  align-items: center;
  justify-content: space-between;
}

.qi-badge {
  @apply bg-blue-500 text-white px-2 rounded ml-3;
}

.qi-color-transition {
  transition: all 0.3s ease;
}

pre {
  white-space: inherit;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status-icon {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: 0 10px;
  width: 10px;

  &.active {
    @apply bg-emerald-500;
  }
}

.hidden {
  display: none !important;
}

.otherStatusHeader {
  padding: 0.5rem;
  width: 446px;
}

.otherStatusBody {
  padding: 0.5rem;

  .card-body {
    background-color: $white;
    padding-top: 0;
  }
}

.route-covered {
  padding: 0.5rem 0;
}

.perticle-info {
  width: calc(100% - 250px);
}

.upload-vehicle-photo-wrapper {
  padding: 0 15px;
}

.upload-vehicle-photo {
  border: 1px dashed #aec2cc;
  height: 180px;
  padding: 15px;
  text-align: center;
  width: 180px;

  .icon-container {
    align-items: center;
    background-color: #707070;
    display: inline-flex;
    border-radius: 50%;
    height: 50px;
    justify-content: center;
    width: 50px;
  }
}

.password-filed {
  position: relative;

  .password-show-hide {
    border-color: transparent transparent #919191 transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    position: absolute;
    right: -2px;
    top: -12px;
    width: 25px;

    &.show {
      border-color: #919191 transparent transparent transparent;
      top: 12px;

      &::after {
        background-color: #999;
        border-radius: 50%;
        content: " ";
        display: inline-block;
        height: 7px;
        left: 7px;
        position: absolute;
        top: -1px;
        width: 8px;
      }
    }
  }
}

.pagination-lg {
  .page-link {
    border-radius: 2px;
    font-size: $baseFontSize;
    padding: 5px 0;
    text-align: center;
    width: 30px;
  }
}

.admin-dashboard {
  .nav-link {
    font-weight: bold;
    font-size: 18px;
  }
}

.application-card {
  padding: 15px;

  img {
    opacity: 0.25;
  }
}

.column {
  padding: 0;
}

.application-view {
  .left-panel {
    @apply bg-white;

    margin-right: 15px;
    width: 200px;

    .nav-pills {
      .nav-link {
        border-radius: 0;
        position: relative;

        &.dropdown-toggle::after {
          position: absolute;
          right: 5px;
          top: 18px;
          vertical-align: 0;
        }
      }
    }
  }

  .right-panel {
    padding: 15px;
    width: calc(100% - 240px);
  }
}

.details-vie_summary_list {
  @apply bg-white mb-4;

  &_item {
    @apply flex px-6 py-3;

    border-bottom: 1px solid #eee;
  }

  &_label {
    width: 180px;

    &::after {
      content: ":";
    }
  }
}

.qi-list-view {
  &.fleetTable {
    .name {
      width: 260px;
    }

    .maping {
      width: 330px;

      .code-format {
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #eee;
        padding: 15px;
      }
    }

    .qi-list-view_list {
      height: calc(100vh - 292px);
    }
  }
}

.no-data {
  @apply flex flex-col items-center justify-center rounded text-slate-400 text-xl bg-slate-200;

  height: calc(100vh - 244px);

  .no-data-icon {
    @apply p-8 rounded-full bg-sky-100 mb-4;

    height: 150px;
    width: 150px;

    .icon {
      height: 100%;
      fill: #c3d5df;
      width: 100%;
    }
  }

  &_message {
    text-align: center;
  }
}

.showpage-blocks {}

.roles-and-applications-set {
  @apply mb-2;

  .title {
    @apply text-black;
  }

  &_list {
    @apply w-1/3;
    @apply ml-3;
  }
}

.main-container {
  &_header {
    @apply flex items-center justify-between justify-center mb-5 mt-2;

    min-height: 40px;

    &_title-and-search-group {
      @apply flex items-center;
    }

    &_link {
      @apply flex w-full;

      align-items: center;
      justify-content: start;
      overflow: auto;

      &_item {
        @apply mr-4 text-base;

        color: rgb(202, 191, 191);
        transition: all 0.35s ease;

        strong {
          white-space: nowrap;
        }

        &.active,
        &:hover {
          color: $defultTextLinkColor;
        }
      }
    }
  }

  &_secondary-header {
    @apply flex items-center justify-between mb-3 w-full;

    .showing-shortlisted {
      @apply bg-white;

      font-size: 13px;
      max-width: 280px;
      overflow: hidden;
      padding: 1px 10px;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media (min-width: 1800px) {
        max-width: 500px;
      }
    }

    &.content-right {
      @apply justify-end;
    }
  }
}

.no-item-availlable {
  background-color: $informationAlert;
  padding: 3px 0;
  text-align: center;
  width: 100%;
}

.available-in-list {
  max-height: 250px;
  min-height: 58px;
  padding: 1px 2px;

  &_item {
    @apply py-1 px-3 flex flex-col;

    border-radius: 3px;
    margin: 1px 0;
    transition: all 0.35s ease;

    &:nth-child(2n-1) {
      background-color: #f7f7f7;
    }

    &:hover {
      @apply text-white cursor-pointer;

      background-color: #31c786;
    }

    &.active {
      @apply text-white cursor-pointer bg-sky-100 text-zinc-500;
    }

    &_primary-data {}

    &_secondary-data {
      font-size: 0.75rem;
    }
  }
}

.backends-owner {
  .available-in-list {
    &.backends {
      .available-in-list_item {
        @apply pr-10 relative;

        &.active {
          @apply bg-sky-50;
          color: #333;
        }

        &::before {
          @apply absolute rounded-full;

          border: 2px solid $white;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
          content: " ";
          display: inline-block;
          height: 13px;
          right: 15px;
          top: 8px;
          width: 13px;
        }
      }

      .by-superadmin-enabled::before {
        @apply bg-sky-400;
      }

      .by-superadmin-disabled::before {
        @apply bg-gray-400;
      }

      .by-client-enabled-default::before {
        @apply bg-green-400;
      }

      .by-client-disabled-default::before {
        @apply bg-gray-300;
      }

      .by-client-enabled-not-default::before {
        @apply bg-green-700;
      }

      .by-client-disabled-not-default::before {
        @apply bg-gray-200;
      }
    }
  }
}

.device-chip {
  background: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-right: 15px;
  width: calc(100% - 50px);

  .qi-chip {
    @apply text-white;

    background-color: #31c786;

    .qi-chip_cross {
      background-color: #eee;
      margin-left: 10px;
      padding: 3px;

      .icon {
        fill: #ff5500;
      }
    }
  }
}

.new-device-chip {
  @apply flex justify-end items-center;

  width: 40px;

  .qi-dropdown_toggle {
    text-align: center;
  }

  .qi-dropdown_menu {
    right: 50px;
    top: -30px;

    &::before,
    &::after {
      @apply absolute;

      border-style: solid;
      border-width: 8px 0 8px 16px;
      content: " ";
      display: inline-block;
      height: 0;
      right: -17px;
      width: 0;
    }

    &::before {
      border-color: transparent transparent transparent #0767d0;
      top: 36px;
    }

    &::after {
      border-color: transparent transparent transparent $white;
      right: -14px;
      top: 36px;
    }
  }
}

.qi-chip {
  &.by-superadmin-enabled {
    @apply bg-sky-400;
  }

  &.by-superadmin-disabled {
    @apply bg-gray-400;
  }

  &.by-client-enabled-default {
    @apply bg-green-400;
  }

  &.by-client-disabled-default {
    @apply bg-gray-300;
    color: #666;
  }

  &.by-client-enabled-not-default {
    @apply bg-green-700;
  }

  &.by-client-disabled-not-default {
    @apply bg-gray-200;
    color: #777;
  }
}

.icon-wrapper {
  &.add-new {
    @apply cursor-pointer;

    height: 32px;
    width: 32px;

    .icon {
      @apply rounded-full p-2;

      background-color: #31c786;
      fill: $white;
      height: 100%;
      stroke: $white;
      width: 100%;
    }
  }
}

.qi-input {
  .icon-wrapper {
    &.add-new {
      margin: -10px 0 -10px 10px;
    }
  }
}

.applications-and-role {
  width: 350px;
}

.application-role {
  @apply flex items-center my-1;

  &_application {
    @apply relative;

    max-width: 155px;

    &::after {
      @apply absolute;

      background-color: #ccc;
      content: " ";
      display: inline-block;
    }

    &::after {
      height: 1px;
      right: -15px;
      top: 50%;
      width: 10px;
    }

    &_name {
      @apply truncate;

      display: block;
      width: 100%;
    }

    &_roles {
      @apply flex flex-col pl-3 relative;

      margin-left: 15px;

      &::before {
        @apply absolute;

        background-color: #ccc;
        content: " ";
        display: inline-block;
        height: calc(100% - 29px);
        left: 1px;
        top: 15px;
        width: 1px;
      }

      &_item {
        @apply relative rounded bg-sky-200 px-2;

        margin: 1px 0;
        max-width: 100px;

        &.admin {
          @apply bg-red-100;
        }

        &::before {
          @apply absolute;

          background-color: #ccc;
          content: " ";
          display: inline-block;
          height: 1px;
          left: -10px;
          top: 50%;
          width: 10px;
        }

        &_name {
          @apply truncate;

          display: block;
          width: 100%;
        }
      }
    }
  }
}

.qi-list-view.variable-mapping {
  // @apply mb-5;

  border: 1px solid #e7edf5;

  .qi-select_label {
    display: none;
  }

  select,
  input {
    border: 1px solid #eee;
    margin: 0;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }

  input {
    border-radius: 3px;
    padding: 2px 10px;
  }
}

.action-btn {
  @apply mx-2 cursor-pointer;
}

.page-count-select {
  .qi-custom-select {
    &_dropdown {
      transform: translate3d(0px, -300px, 0px);
    }
  }
}

.tracking-dashboard {}

.add-admin-user {
  &_list {
    border: 1px solid #e7edf5;
  }

  .qi-list-view_header,
  .qi-list-view_list_item {
    min-height: 2.5em;

    .qi-list-view_column {
      width: calc((100% - 100px) / 3);
    }

    .action {
      @apply justify-end;
      width: 100px;
    }
  }

  &_form {
    border-color: #e7edf5;
    border-style: solid;
    border-width: 0 1px 1px;

    input {
      border-radius: 4px;
      padding: 3px 5px;

      &:focus-visible {
        outline: 1px solid #3b82f6;
      }
    }

    .small-error {
      font-size: 11px;
      white-space: break-spaces;
    }

    &.http-header {
      .qi-list-view_column {
        width: calc((100% - 100px) / 2);
      }
    }
  }

  &_button-set {
    .icon-wrapper {
      @apply p-2 cursor-pointer;

      &.tick-icon,
      &.cross-icon {
        height: 32px;
        width: 32px;
      }

      &.tick-icon {
        background-color: #31c786;
        margin-right: 5px;

        .icon {
          fill: $white;
        }
      }

      &.cross-icon {
        background-color: #e7edf5;

        .icon {
          fill: #3b82f6;
        }
      }

      .icon {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.add-admin-user-wrapper {
  position: relative;
  width: calc(100% - 14rem);

  .add-admin-user {
    .qi-list-view_list {
      height: auto;
    }
  }

  .qi-input_error {
    right: 3px;
    top: 3px;
  }

  .add-admin-user+.qi-input_error {
    right: 24px;
    top: 14px;
  }
}

.terminal-and-console {
  padding-right: 0;
  text-align: right;
  text-overflow: unset;
  width: 65px;
}

.chip-search {
  @apply p-3;

  &_text {
    border: 1px solid #ccc;
    margin-bottom: 0;
    padding: 10px 15px 5px;
  }
}

.date-range-picker {
  &.qi-input_wrapper {
    width: calc(100% - 10rem) !important;
  }

  .date-range {
    @apply flex justify-between items-center;
  }
}

.crown-icon {
  @apply inline-block p-2 bg-amber-300 rounded-full;

  .icon-crown {
    fill: #cd2d2d;
  }
}

.code-format {
  background: #eee;
  border-radius: 5px;
  border: 1px solid #ccc;
  color: #054891;
  overflow-wrap: break-word;
  padding: 10px;
  white-space: break-spaces;
}

.association-error {
  background-color: $associationAlert;
  border-radius: 5px;
  margin: 15px 15px 0;
  padding: 80px 0;
  text-align: center;

  .cross-icon {
    @apply border-2 rounded-full border-red-500 p-3;

    .icon {
      fill: red;
    }
  }

  &_message {
    @apply mt-6;
  }
}

.more-options-group {
  .qi-dropdown_menu {
    @apply flex flex-col items-center;

    background-color: #f1f9ff;
    box-shadow: 0 0 5px #4293ff80;
    padding: 8px 5px 0;
    right: -15px;
    width: auto;

    .icon-wrapper {
      @apply mb-3;
    }
  }
}

.happenings {
  background-color: transparent;
  max-width: 3em;
  margin: 150px auto 0;
  width: 100%;
}

.authentication {
  .page-title {
    text-align: center;
  }

  .happenings {
    margin-top: 1em;
  }
}

.available-in-list_item.not-selectable {
  background-color: #e7e7e7;
  color: #666;
  cursor: auto;
}

.available-ssl-chip {
  .icon-download {
    margin: 0 10px;

    .icon {
      fill: #1d4ed8;
    }
  }

  .qi-chip_cross {
    margin-left: 10px;
    padding: 3px;

    .icon {
      height: 10px;
      width: 10px;
    }
  }
}

.application-icon-wrapper {
  @apply mr-2 flex justify-center items-center;

  background-color: #fff;
  border-radius: 50%;
  height: 50px;
  overflow: hidden;
  width: 50px;

  .application-icon_wrapper {
    display: contents;
  }

  height: 36px;
  width: 36px;

  &.defult {
    @apply bg-teal-300 rounded-full p-2 mr-2;
  }
}

.application-icon {
  background-color: #e8eef5;
  max-height: 100%;
  max-width: 100%;

  .icon {
    fill: #0767d0;
    margin: 15px;
  }
}

.small-error {
  height: 27px;
}

.upload-csv-note {
  @apply flex mb-3;

  .qi-checkbox_label {
    width: auto;
  }
}

.secondary-header-wrapper {
  @apply flex justify-between;

  &.showing-only-shortlisted {
    @apply justify-end;

    &+.qi-list-view.no-tabs .qi-list-view_list {
      height: calc(100vh - 264px);
    }

    .showing-shortlisted {
      @apply mb-1;
    }
  }
}

.variable-mapping {
  &_list {
    height: auto;
  }
}

/**Quick Fix**/

.profile-image-upload-guide-fix .customization-photo-upload-guide {
  flex-direction: column;
}

.customization-photo-upload-guide h4 {
  @apply mr-3;
}

/* Input type Search default clear off */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/*Input type for phone number and country code*/
.country-code-and-number {
  gap: 10px;

  &_item1 {
    flex: 3;
  }

  &_item2 {
    flex: 1;
    @apply items-center;
  }
}

.subscription-date-and-days {
  display: flex;
  gap: 10px;

  &_item1 {
    //flex: 1;
    flex: 0 0 60%;

    &_edit {
      @apply items-center;
      flex: 3;
    }
  }

  &_item2 {
    flex: 2;
    @apply items-center;
  }

  &_item3 {
    flex: 1;
    //@apply items-center;
  }

  &_item4 {
    flex: 1;
    @apply items-center;
  }
}

.phone-number {
  width: 7rem;
}

.subscription-date {
  width: 11rem;

}

.subscription-day {
  width: 10rem;

}

.subscription-day-extend {
  width: 3rem;
}

.subscription-day-wrapper {
  @apply flex items-center;
  //width: 13rem;
}

.phone-number-wrapper {
  @apply flex items-center;
  width: 13rem;
}

.narrow-multiselect-dropdown {

  .backends,
  .available-in-list {
    max-height: 100px;
  }
}

.AppRoleImage {
  width: 40px;
  height: 40px;
}

.audit-log-shortlisted {
  @apply mb-2;
}

.active-status {
  .qi-input_error_message {
    white-space: unset;
    width: 300px;
  }
}

//To add error message for no configurations added in event configuration plan form
.configurations-error {
  color: $danger;
}

//Track Point Date Picker
.track-points-datepicker-wrapper {
  @apply py-1 px-4 bg-white;

  border: 1px solid #c5d2e0;
  border-radius: 3px;
  font-size: 14px;

  &.report-form-datepicker {
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border-width: 1px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &:hover {
    @apply bg-sky-100 cursor-pointer;
  }

  .calendar-icon>svg {
    fill: #0767d0;
    display: inline-block;
    width: 16px;
    transform: translate(-3px, -1px);
  }

  .icon {
    width: 14px;
    transform: translate(5px, 4.5px);
  }
}

//Geofence events form
.configurations-wrapper {
  border: solid 1px lightgray;
  padding: 20px 20px 5px 20px;
  display: flex;
}

.configurations-wrapper-error {
  border-color: $danger;
}

/**********Columns width*********/
.qi-list-view.devices {
  .qi-list-view_column {

    &.name,
    &.group,
    &.id,
    &.phone-number,
    &.backends,
    &.sim-numberc,
    &.applications {
      min-width: 120px;
      width: calc((100% - 690px) / 7);
    }
  }
}

.qi-list-view {
  &_column {
    &.plate-number-number, &.firmware {
      width: 165px;
    }
  }
}

.qi-list-view.device-models {
  .qi-list-view_column {
    &.device-manufacturer {
      width: 180px;
    }

    &.status {
      width: 120px;
    }

    &.variables {
      width: 100px;
    }

    &.control-signal {
      width: 134px;
    }

    &.devices {
      width: 96px;
    }
  }
}

.qi-list-view.device-manufacturers {
  .qi-list-view_column {

    &.name,
    &.clients {
      min-width: 120px;
      width: calc((100% - 700px) / 2);
    }
  }
}

.qi-list-view.vehicles {
  .qi-list-view_column {

    &.model,
    &.name,
    &.group-name,
    &.plate-number,
    &.engine-number,
    &.chasis-number,
    &.devices {
      min-width: 120px;
      width: calc((100% - 350px) / 7);
    }
  }
}

.qi-list-view.vehicle-specifications {
  .qi-list-view_column {

    &.manufacturer,
    &.vehicle-manufacturer-model,
    &.model-variant,
    &.engine-capacity {
      min-width: 120px;
      width: calc((100% - 1200px) / 4);
    }
  }
}

.qi-list-view.vehicle-variants {
  .qi-list-view_column {

    &.clients,
    &.manufacturer-model,
    &.name,
    &.vehicle-manufacturer-name,
    &.vehicle-manufacturer-model-name {
      min-width: 120px;
      width: calc((100% - 500px) / 3);
    }
  }
}

.qi-list-view.vehicle-models {
  .qi-list-view_column {

    &.clients,
    &.manufacturer,
    &.name {
      min-width: 120px;
      width: calc((100% - 500px) / 3);
    }
  }
}

.qi-list-view.vehicle-manufacturers {
  .qi-list-view_column {

    &.clients,
    &.name {
      min-width: 120px;
      width: calc((100% - 1300px) / 2);
    }
  }
}

.qi-list-view.vehicle-classes {
  .qi-list-view_column {

    &.clients,
    &.name {
      min-width: 120px;
      width: calc((100% - 1400px) / 2);
    }
  }
}

.qi-list-view.vehicle-types {
  .qi-list-view_column {

    &.clients,
    &.name {
      min-width: 120px;
      width: calc((100% - 1400px) / 2);
    }
  }
}

.qi-list-view.variable-types {
  .qi-list-view_column {

    &.clients,
    &.name,
    &.display-name {
      min-width: 120px;
      width: calc((100% - 800px) / 3);
    }
  }
}

.qi-list-view.variable-mappings {
  .qi-list-view_column {

    &.clients,
    &.name {
      min-width: 120px;
      width: calc((100% - 1300px) / 2);
    }
  }
}

.qi-list-view.vehicle-groups {
  .qi-list-view_column {

    &.name,
    &.added-by {
      min-width: 120px;
      width: calc((100% - 800px) / 2);
    }
  }
}

.qi-list-view.device-groups {
  .qi-list-view_column {

    &.name,
    &.added-by {
      min-width: 120px;
      width: calc((100% - 800px) / 2);
    }
  }
}

.qi-list-view.driver-groups {
  .qi-list-view_column {

    &.name,
    &.added-by {
      min-width: 120px;
      width: calc((100% - 800px) / 2);
    }
  }
}

.qi-list-view.user-groups {
  .qi-list-view_column {

    &.name,
    &.added-by {
      min-width: 120px;
      width: calc((100% - 800px) / 2);
    }
  }
}

.qi-list-view.drivers {
  .qi-list-view_column {

    &.name,
    &.group-name,
    &.email,
    &.phone-number,
    &.description {
      min-width: 120px;
      width: calc((100% - 1300px) / 5);
    }
  }
}

.qi-list-view.users {
  .qi-list-view_column {

    &.name,
    &.added-by {
      min-width: 120px;
      width: calc((100% - 1300px) / 2);
    }
  }
}

.qi-list-view.roles {
  .qi-list-view_column {

    &.name,
    &.application {
      min-width: 120px;
      width: calc((100% - 800px) / 2);
    }
  }
}

.qi-list-view.audit-trail {
  .qi-list-view_column {

    &.name,
    &.added-by,
    &.email {
      min-width: 120px;
      width: calc((100% - 350px) / 3);
    }
  }
}

.qi-list-view.backends {
  .qi-list-view_column {

    &.name,
    &.host,
    &.path {
      min-width: 120px;
      width: calc((100% - 1400px) / 3);
    }
  }
}

.qi-list-view.geofence {
  .qi-list-view_column {

    &.name,
    &.added-by {
      min-width: 120px;
      width: calc((100% - 800px) / 2);
    }
  }
}

.qi-list-view.obd-codes {
  .qi-list-view_column {

    &.name,
    &.offset {}
  }
}

.qi-list-view.target-sections {
  .qi-list-view_column {
    &.name {
      min-width: 120px;
      width: calc((100% - 1400px) / 3);
    }
  }
}

.qi-list-view.clients {
  .qi-list-view_column {

    &.name,
    &.email,
    &.alt-email,
    &.applications {
      min-width: 120px;
      width: calc((100% - 1000px) / 4);
    }
  }
}

.qi-list-view.users {
  .qi-list-view_column {

    &.name,
    &.email,
    &.phone-number,
    &.clients {
      min-width: 120px;
      width: calc((100% - 600px) / 4);
    }
  }
}

.qi-list-view.audit-trail {
  .qi-list-view_column {

    &.entry,
    &.updated-by {
      min-width: 120px;
      width: calc((100% - 500px) / 2);
    }
  }
}

.qi-list-view.event-configuration {
  .qi-list-view_column {

    &.name,
    &.event-configuration-plans {
      min-width: 120px;
      width: calc((100% - 800px) / 2);
    }
  }
}

.qi-list-view.event-configuration-plan {
  .qi-list-view_column {

    &.name,
    &.event-configuration {
      min-width: 120px;
      width: calc((100% - 500px) / 2);
    }
  }
}

.qi-list-view.audit-trail-quick-view {
  .qi-list-view_column {

    &.entity,
    &.event,
    &.updated-by {
      min-width: 120px;
      width: calc((100% - 500px) / 3);
    }

    &.date {
      @apply mr-8;
    }
  }
}

.confirm {
  .qi-modal_body {
    .page-title {
      font-size: 1.25em;
      font-weight: 700;
    }
  }
}

.custom-tooltip {
  .qi-dropdown_menu {
    padding: 0;
  }

  &_data {
    background-color: #eee;
    border-radius: 3px;
    display: inline-block;
    padding: 5px 10px;
    transition: all 0.35s ease;

    &.copied {
      background-color: #cffce8;
    }

    &_copy {
      display: inline-block;
      cursor: pointer;
      margin-left: 15px;
    }
  }
}

//For disabling the arrow in number type input type
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//For selected fields in csv upload
.qi-custom-select_dropdown_text {
  .active-fields {
    background-color: #d6e9fd;
  }
}

//For adding csv upload button for assets
.header-buttons-wrapper {
  @apply flex justify-end;
  gap: 15px;
}

.actions-information {
  @apply mb-2;
}

//For adding dropdown heading in classes and types dropdowns in vehicle specification form
.dropdown-heading {
  @apply font-bold ml-2 mt-3;
}

/* Add this CSS to your stylesheets */
.tab-buttons {
  display: flex;
  margin-bottom: 10px;
}

.tab-buttons button {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.tab-buttons button:focus {
  outline: none;
}

.tab-buttons button.active {
  background-color: #ddd;
  /* Change the background color for active tab */
}

.qi-custom-select_dropdown {

  &.preferred-language,
  &.variable-type,
  &.audit-trail,
  &.resource-type,
  &.client,
  &.geolocation-plan,
  &.obd-type,
  &.select-app,
  &.geolocation-plan,
  &.device-manufacturer,
  &.device-model,
  &.vehicle-model,
  &.model,
  &.vehicle-manufacturer,
  &.manufacturer,
  &.vehicle-specification,
  &.specifications,
  &.plate-no,
  &.engine-no,
  &.chasis-no,
  &.geolocation-plan,
  &.geofence,
  &.variable-mapping,
  &.country-code,
  &.simcard-no,
  &.phn-no,
  &.target-variable,
  &.target-section,
  &.user,
  &.variant,
  &.types,
  &.classes,
  &.role,
  &.group,
  &.user,
  &.vehicle-type,
  &.vehicle-class,
  &.email,
  &.devices,
  &.active,
  &.password,
  &.trip-configuration,
  &.name,
  &.id-imei,
  &.sms-username,
  &.backend-username,
  &.sms-password,
  &.backend-password,
  &.enabled,
  &.applications,
  &.backends,
  &.firmware-version,
  &.event-configuration-plan,
  &.first-name,
  &.last-name,
  &.country,
  &.section,
  &.type,
  &.unit,
  &.variable,
  &.mobile-service-provider,
  &.variable-mapping,
  &.primary-sim-card,
  &.report-configuration {
    max-height: 200px;
    overflow: auto;
  }
}

/****Global Dashboard****/
.global-dashboard-header {
  justify-content: space-between;
  padding: 30px 0;
}

.global-dashboard {
  max-width: 1024px;
  padding: 15px;
  margin: 0 auto;
}

.clients-and-applications-wrapper {
  border: 1px solid #ddd;

  .search-form {
    background-color: #fff;
    margin-left: 0;
    padding: 15px;
  }
}

.clients-and-applications {
  max-height: calc(100vh - 400px);
  overflow: auto;
}

.available-application {
  &_title {
    @apply text-center;

    font-size: 24px;
    margin: 30px 0 50px;
  }

  &_block {
    align-items: center;
    border-top: 1px solid #e6e6e6;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 15px;

    &_title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 25px;
    }

    &_client {
      @apply flex items-center;

      text-transform: capitalize;
      width: 200px;
    }
  }

  &_list {
    display: flex;
    justify-content: space-between;
    list-style: none;
    width: calc(100% - 200px);

    &_item {
      align-items: center;
      background-color: #fff;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 15px;
      min-width: calc(50% - 30px);
      padding: 30px 0;
      transition: all 0.35s ease;
      flex-grow: 1;

      &:hover {
        box-shadow: 0 0 5px #1960bd;
      }

      &.super-admin {
        @apply mx-0;
      }

      .application-icon {
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 15px;
      }
    }
  }
}

.app_list {
  display: flex;
  justify-content: space-between;
}

.not-accessible {
  background: rgb(220, 220, 220);
  cursor: not-allowed;
}

.quick-dashboard {
  margin: 7px 15px 0 0;
  position: relative;

  .switch-apps {
    background-color: #fff;
    border: 1px solid #0767d0;
    box-shadow: 0 0 5px #0767d0;
    position: absolute;
    right: 0;
    z-index: 9999;

    &_list {
      &_item {
        @apply flex p-3;

        align-items: center;
        border-bottom: 1px solid #eee;
        transition: all 0.35s ease;

        &.active,
        &:hover {
          background-color: #3b82f6;
          color: #fff;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .icon-wrapper {
        margin-bottom: 0;

        .icon {
          margin: 0;
        }
      }

      span,
      strong {
        white-space: nowrap;
      }
    }
  }
}

.qi-header_nav {
  display: flex;
  justify-content: space-between;
}

/*Input type for speed limit and unit in events form of type geofence*/
.speed-limit-and-unit {
  @apply flex justify-between;
  gap: 12px;

  &_item1 {
    flex: 2;
  }

  &_item2 {
    @apply items-center;
    flex: 1;
    width: 25%;
  }
}

.speed-limit {
  width: 7rem;
}

.speed-limit-wrapper {
  @apply flex items-center;
  width: 13rem;
}

.unit-dropdown {
  left: 76px;
  width: 80px;
}

.confirmation-message {
  @apply text-center;

  .icon {
    @apply mb-4;

    fill: $alert;
    height: 3em;
    width: 3em;
  }

  p {
    @apply mb-3;
  }
}

/* Report Configuration Popup selector */

.add-edit-report-type-list .qi-table-custom {
  .qi-list-view_column {
    width: calc((100% - 86px) / 3);

    &.action {
      width: 86px;
    }
  }

  .qi-table-custom_form {
    .displayname-section {
      width: calc(100% - 86px);
    }

    .dispplayname {
      padding: 0.5em;

      .qi-input_label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .dispplayname,
    .qi-list-view_column {
      width: calc(100% / 3);
    }

    .qi-list-view_column.action {
      width: 86px;
    }
  }

  .qi-custom-select {
    height: 46px;
  }

  .qi-custom-select_dropdown.show {
    left: 0;
    width: 500px;
  }
}

.small-error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

/* Event configuration geofence dropdown */
.qi-form-element:nth-last-child(2).event-configuration-geofence-wrapper .qi-custom-select_dropdown.show {
  bottom: auto;
  top: 46px;
}

/*Default trip configuration in device form */
.default-model {
  opacity: 0.6;
}

.link-export {
  all: unset;
}

.custom-field {
  top: 14px;
}

.scroll-wrapper {
  overflow: hidden;
}

.warning-popup-title {
  color: $danger;
}

.disabled {
  background-color: #f0f0f0;
  /* Light grey background */
  /* Grey text */
  //pointer-events: none;
  /* Disable interactions */
}

.grey {
  background-color: $disabledFieldBackgroudColor;
  /* Light grey background */
  /* Grey text */
}

.display_config_variable {

  .qi-select_wrapper {
    height: 24px;

    &:first-child {
      width: 310px;
    }
  }
}



.mapboxgl-ctrl-bottom-right {
  display: none;
}