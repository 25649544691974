.qi-dropdown {
  @apply relative;

  &_toggle {
    @apply cursor-pointer;
  }

  &_menu {
    @apply bg-white border rounded text-right;

    border-color: #0767d0;
    position: absolute;
    right: 0;
    width: 350px;
    z-index: 999;
  }

  &_list {
    &_item {
      @apply px-6 py-2;

      .qi-custom-select {
        &_inputwrapper {
          @apply mb-0;
        }
      }
    }
  }
}
