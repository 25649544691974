.report-container {
  padding: 10px 20px;

  .qi-list-view_list {
    height: calc(100vh - 240px);
  }

  .csv-generation-container {
    width: 100%; /* Increased the max-width for better alignment */
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    border-radius: 10px;
    //box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.2);
  }

  .form-container {
    display: flex;
    flex-wrap: wrap; /* Allow elements to wrap to the next line */
  }

  .input-group {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .input-group label {
    display: block;
    margin-bottom: 5px;
  }

  .input-group select,
  .input-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 33px;
  }

  .button-group {
    flex: 1;
    text-align: right;
    margin-top: 10px; /* Added margin for separation */
  }

  .generate-button,
  .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px 5px;
  }

  .generate-button {
    background-color: #31c786;
    color: #fff;
  }

  .cancel-button {
    background-color: #ccc;
    color: #000;
  }

  .report-list {
    margin: 10px auto;
    .no-data,
    .qi-list-view {
      height: calc(100vh - 180px);
    }
  }
  .report-container-button {
    display: flex;
    justify-content: space-between;
  }
}

.daterangepicker {
  z-index: 99999 !important;
}
.disabled-button {
  color: #ccc; /* Change color to indicate disabled state */
  cursor: not-allowed; /* Change cursor to indicate not clickable */
  pointer-events: none; /* Disable pointer events */
}
.qi-list-view_column {
  width: 200px;
  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; /* Adjust this value to control the truncation width */
  }
}

.report-configuration {
  .qi-custom-select_dropdown {
    ul {
      height: 200px;
      overflow-y: scroll;
    }
  }
}
.timezone-dropdown {
  .qi-custom-select_dropdown {
    ul {
      height: 200px;
      overflow-y: scroll;
    }
  }
}
.track-points-timerange {
  display: flex;

  .date-range {
    white-space: nowrap;
  }
}
