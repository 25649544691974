.qi-chip {
  @apply inline-flex px-2 py-1 justify-between items-center m-1 rounded-xl;

  background-color: #d3d6d7;
  font-size: 0.85rem;
  min-width: 80px;

  &_data {
    @apply flex flex-col;
  }

  &_cross {
    @apply flex  border justify-between items-center rounded-full p-2;

    height: 18px;
    width: 18px;
    background-color: #5f6a6d;
    cursor: pointer;

    .icon {
      height: 10px;
      width: 10px;
      fill: white;
    }
  }

  &.alert {
    background-color: #f96868;
  }
}

.qi-multiselect-dropdown {
  @apply flex px-4 py-3 mb-4 border rounded;

  &.no-outline {
    border: none;

    &:hover {
      background-color: white;
    }
  }

  &.disabled {
    background-color: $disabledFieldBackgroudColor;
    border-color: $disabledFieldBorderColor;

    .qi-input_label {
      color: $disabledFieldInputLabelColor;
    }

    &:hover {
      background-color: $disabledFieldBackgroudColor;
      border-color: $disabledFieldBorderColor;
    }
  }
  border-color: #c5d2e0;
  transition: all 0.3s ease;

  &:hover {
    background-color: #eff6fd;
    border-color: #0767d0;
  }

  &:focus-visible,
  &:focus {
    outline: 1px solid #0767d0;
  }

  &_label {
    @apply w-40 font-semibold;
  }

  &_wrapper {
    @apply grow relative;
    width: calc(100% - 10rem);
  }

  &_text {
    @apply border-0;
    background-color: transparent;

    &:focus {
      outline: 0;
    }
  }

  &.error {
    @apply relative;

    border: 1px solid #f96868;

    .password-show-hide {
      display: none;
    }
  }
  &_chip-container {
    display: flex flex-wrap;
  }

  &_error {
    @apply absolute;

    color: #f96868;
    top: 9px;
    right: 48px;
    .icon {
      fill: #f96868;
    }
    &_message {
      @apply absolute bg-white p-2;

      border-color: #eee;
      border-radius: 3px;
      border-style: solid;
      border-width: 1px 0 0 1px;
      box-shadow: 7px 4px 8px #ddd;
      display: none;
      padding: 3px 10px;
      right: 35px;
      top: -6px;
      white-space: nowrap;

      &::after {
        @apply absolute;

        border-color: transparent transparent transparent #fff;
        border-style: solid;
        border-width: 6px 0 6px 10px;
        bottom: 5px;
        content: " ";
        display: inline-block;
        right: -9px;
      }
    }

    &:hover {
      .qi-multiselect-dropdown_error_message {
        display: inline-block;
      }
    }
  }
}

/**
.qi-form-element:nth-child(2) {
  border: 2px solid red;
}
*/
