/*General Colors*/
$black: #000000;
$white: #ffffff;
$primary: #1d516e;
$secondary: #6c757d;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$alert: #ff9900;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;

$bodyBackgroundColor: #f5f6fa;
$defultFontColor: #1a2934;
$defultTextLinkColor: #0767d0;
$selectedRow: #f3faff;

/*Custom colors for generic components*/
$header-bg: #3288b9;

$informationAlert: #fffbf3;
$associationAlert: #fff3e5;
$disabledFieldBackgroudColor: #eee;
$disabledFieldBorderColor: #eee;
$disabledFieldInputLabelColor: #999;
$copyrightTextColor: #cdcdcd;
