.qi-custom-select {
  @apply relative flex px-4 py-3 mb-4 border rounded static;

  &.disabled {
    background-color: $disabledFieldBackgroudColor;
    border-color: $disabledFieldBorderColor;

    .qi-input_label {
      color: $disabledFieldInputLabelColor;
    }

    &:hover {
      background-color: $disabledFieldBackgroudColor;
      border-color: $disabledFieldBorderColor;
    }
  }

  // &_list {
  //   overflow: auto;
  // }

  &_dropdown {
    @apply bg-white border-solid border-2 absolute border rounded w-full;

    border-color: #0767d0;
    display: none;
    left: 175px;
    top: 47px;
    width: calc(100% - 174px);
    z-index: 1000;

    &.show {
      display: block;
    }

    li,
    &_list_item {
      @apply px-4 py-2;

      border-radius: 3px;
      cursor: pointer;
      margin: 1px;
      transition: all 0.3s ease;

      &:hover {
        background-color: #eff6fd;
      }

      &.active {
        background-color: #d6e9fd;
      }

      &_chip {
        @apply rounded-md bg-cyan-100 p-1 mr-2;

        font-size: 0.8em;
      }
    }
  }

  &:hover,
  &.focus {
    background-color: #eff6fd;
    border-color: #0767d0;
  }

  &.error {
    border: 1px solid #f96868;

    .password-show-hide {
      right: 25px;
    }
  }

  &_inputwrapper {
    @apply flex relative w-full;

    &_label {
      @apply w-40;
    }

    &_wrapper {
      @apply grow;
    }

    &_text {
      @apply border-0 grow;
      background-color: transparent;

      &:focus {
        outline: 0;
      }
    }

    &_error {
      color: #f96868;
    }
  }

  &_search {
    @apply mt-4 mr-6 mb-0 ml-6;
  }
}
