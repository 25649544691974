.devices {
  .geocoders {
    min-width: 200px;
  }
}

.status-icon {
  border-radius: 50%;
  height: 24px;
  padding: 3px 5px 0;
  width: 24px;

  &.active {
    background-color: #CFFCE8;

    .icon {
      fill: #31C786;
    }
  }

  &.inactive {
    background-color: #fceacf; 

    .icon {
      fill: #b3992f;
    }
  }

  .icon {
    width: 100%;
  }
}
