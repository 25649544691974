.modal {
  @apply mb-6;

  &_tab {
    @apply cursor-pointer;

    background-color: #eee;
    border: 1px solid #eee;
    border-radius: 4px 4px 0 0;
    border-bottom: 0;
    color: #666;
    font-weight: 700;
    margin-right: 10px;
    padding: 6px 15px;

    &:hover,
    &.active {
      color: #333;
    }

    &.active {
      @apply bg-white;
    }
  }

  .qi-list-view_list {
    height: auto;
  }

  &_tab-content {
    @apply border p-3;
    margin-top: -1px;
    min-height: calc(100vh - 400px);
    &.command-status {

    }

    .qi-multiselect-dropdown {
      margin-bottom: 5px;
      padding: 0;
    }

    .qi-list-view_list {
      max-height: calc(100vh - 535px)
    }
  }
}

.terminal-response {
  @apply p-2;

  max-height: calc(100vh - 535px);
  overflow: auto;

  &_item {
    @apply border p-2 rounded mb-2 items-start;

    background-color: #f7f7f7;

    .response-and-date {
      @apply flex flex-col;

      margin-right: 10px;
      width: 180px;

      .response-for {
        @apply truncate;

        max-width: 100%;
      }

      .date-time-stamp {
        @apply text-sm;
      }
    }

    .status {
      width: calc(100% - 190px);
    }
  }
}

.terminal-title {
  font-weight: bold;
  padding-left: 15px;
}

.command-sending-status {
  border: 1px solid #e7edf5;

  .qi-list-view_list {
    height: auto;
    max-height: 400px;
  }
}

.command-sending-form {
  @apply p-3 mb-3;

  border-bottom: 1px solid #eee;

  &_filed-group {
    @apply flex justify-between;
    & div {
      align-items: center;
    }
    .raw-command {
      .qi-input_wrapper {
        display: flex;
        align-items: center;
      }
    }
  }

  .command-type,
  .delivery-mode {
    width: calc(30% - 8px);
  }

  .raw-command {
    width: 28%;
  }

  .qi-btn {
    margin-top: 0.5rem;
  }
}
