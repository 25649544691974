.customization {
  @apply flex flex-wrap;

  &_block {
    @apply border p-3 bg-white;

    margin-right: 15px;
    margin-bottom: 15px;
    width: calc(33.33% - 10px);

    &:nth-child(3n) {
      margin-right: 0;
    }

    &_header {
      @apply flex items-center justify-between px-2 mb-3;

      .edit {
        cursor: pointer;
        padding-top: 4px;
      }

      &_brand {
        .qi-photo-uploader_container {
          background: none;
          border: navajowhite;
          box-shadow: none;
          height: 120px;
        }
      }
    }
  }
}

.customization_block_header_brand {
  .qi-photo-uploader {
    height: auto;

    &_action {
      .upload-button {
        background-color: transparent;
        color: $defultTextLinkColor;
      }
    }
  }
}
