.qi-nav-bar {
  @apply flex flex-col p-3 justify-between;

  background-color: #0767d0;
  height: calc(100vh - 57px);
  position: relative;
  z-index: 999;

  &_navigation {
    @apply flex flex-col;

    &_item {
      @apply flex items-center justify-center relative mb-4;

      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      height: 40px;
      width: 40px;
      z-index: 2;

      &:hover,
      &.active {
        background-color: rgba(0, 0, 0, 0.6);
        border: 2px solid #719cd2;
        box-shadow: 0 0 5px #000;

        .icon {
          fill: #fff;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        cursor: default;
      }

      &_info {
        @apply absolute flex items-center h-full p-4 ml-4 text-black capitalize hidden;

        background-color: #054891;
        border-radius: 3px;
        border-right: 3px solid #31c786;
        color: #fff;
        left: calc(100% - 2px);
        top: 0;
        transition: all 0.3s ease;

        &::before {
          border-color: transparent #054891;
          border-style: solid;
          border-width: 8px 15px 8px 0;
          content: " ";
          display: block;
          height: 0;
          left: -13px;
          position: absolute;
          top: 12px;
          width: 0;
        }
      }

      &:hover {
        background-color: #054891;

        .qi-nav-bar_navigation_item_info {
          display: flex;
          white-space: nowrap;
        }
      }
    }

    &_sub-nav {
      &_link {
        position: relative;
      }

      &_item {
        @apply p-3;

        position: relative;
        text-align: center;

        &_link {
          position: relative;

          &.active {
            .icon {
              fill: #fff;
            }

            &::after {
              border-color: transparent #f5f6fa;
              border-style: solid;
              border-width: 6px 10px 6px 0;
              content: " ";
              display: block;
              height: 0;
              position: absolute;
              right: -24px;
              top: -3px;
              width: 0;
            }
          }

          &.visible {
            &:hover {
              .icon {
                fill: #fff;
              }

              .qi-nav-bar_navigation_sub-nav_nesting-nav {
                display: block;
              }

              .qi-nav-bar_navigation_sub-nav_item_info {
                display: inline-block;
              }

              &::after {
                border-color: transparent #f5f6fa;
                border-style: solid;
                border-width: 6px 10px 6px 0;
                content: " ";
                display: block;
                height: 0;
                position: absolute;
                left: 22px;
                top: -3px;
                width: 0;
                z-index: 999;
              }
            }
          }
        }

        &_info {
          background-color: #eee;
          border: 1px solid #0767d0;
          border-radius: 3px;
          box-shadow: 0 0 5px rgba(0, 40, 255, 0.35);
          display: none;
          left: 30px;
          padding: 5px 10px;
          position: absolute;
          top: -15px;
          white-space: nowrap;
          z-index: 999;
        }

        &:hover {
          .qi-nav-bar_navigation_sub-nav_nesting-nav {
            display: block;
          }
        }
      }

      &_nesting-nav {
        background-color: #fff;
        border: 1px solid #0767d0;
        border-radius: 0.25em;
        box-shadow: 0 0 5px rgba(0, 73, 151, 0.75);
        display: none;
        left: 3.75em;
        position: absolute;
        text-align: left;
        top: 0;
        z-index: 1;

        &_item {
          &_link {
            display: block;
            min-width: 10em;
            padding: 0.5em 1em;
            transition: all 0.35s ease;

            &:hover {
              background-color: rgb(240, 249, 255);
            }
          }
        }

        &::after {
          border-color: transparent #fff;
          border-style: solid;
          border-width: 6px 10px 6px 0;
          content: " ";
          display: block;
          height: 0;
          position: absolute;
          left: -10px;
          top: 14px;
          width: 0;
        }
      }
    }
  }
}

.copyright {
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  position: relative;
  text-align: center;
  z-index: 99;

  &_info {
    background-color: #eee;
    border-radius: 3px;
    color: #333;
    display: none;
    left: 10px;
    padding: 5px 15px;
    position: absolute;
    top: -10px;
    white-space: nowrap;
  }

  &:hover {
    .copyright_info {
      display: inline-block;
    }
  }
}
