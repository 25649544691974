.trips-list-wrapper {
  .track-cardlist {
    @apply bg-white p-2 mb-2;

    border: 1px solid #eee;
    border-radius: 3px;
    color: #444;
    font-weight: 700;
    margin: 7px 0;
    transition: all 0.35s ease;
    width: calc(33.33% - 8px);

    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .start-time-and-location, .activity-details, .end-time-and-location {
      padding-left: 15px;
      position: relative;

      &::before {
        background-color: #0767d0;
        content: " ";
        display: inline-block;
        height: 100%;
        left: 1px;
        position: absolute;
        width: 3px;
      }
    }

    .start-time-and-location, .end-time-and-location {
      &::after {
        content: " ";
        display: inline-block;
        height: 12px;
        left: -3px;
        position: absolute;
        width: 12px;
      }
    }

    .start-time-and-location {
      &::before {
        top: 20px;
      }

      &::after {
        border: 3px solid #333;
        border-radius: 50%;
        top: 7px;
      }
    }

    .activity-details {
      @apply py-2;

      &::before {
        top: 0;
      }
    }

    .end-time-and-location {
      &::before {
        bottom: 20px;
      }

      &::after {
        border: 3px solid #ff6600;
        bottom: 7px;
        border-radius: 50% 50% 50% 0;
        transform: rotate(-45deg);
      }
    }

    .stand-card_info {
      width: calc(100% - 160px);
    }

    .stand-card_events {
      @apply ml-3 text-right;

      width: 160px;
    }

    p {
      margin: 5px 0;
    }

    .trip-card,
    .stand-card {
      padding: 10px 15px;
    }

    .activities_header {
      @apply flex justify-between bg-sky-50 py-2 px-3;
    }

    .other_data {
      text-align: right;
    }
  }
}
