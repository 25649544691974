.profile-photo-upload {
  .icon-wrapper {
    @apply bg-white;

    border-radius: 10px;
  }

  .qi-input_error_message {
    width: 150px;
    white-space: normal;
  }
}

.qi-photo-uploader {
  @apply relative;

  height: 180px;
  width: 180px;

  &_container {
    @apply relative border border-8 border-white shadow-lg bg-sky-50;

    height: 100%;
    padding: 10px;

    .icon-wrapper {
      @apply absolute p-1 rounded-full cursor-pointer;

      background-color: rgba(32, 31, 31, 0.85);
      height: 20px;
      right: -10px;
      top: -10px;
      width: 20px;
      z-index: 100;

      .icon {
        fill: white;
        height: 100%;
        width: 100%;
      }
    }
  }

  &_guide-text {
    @apply px-4 text-slate-500;
  }

  &_file-field {
    @apply hidden;
  }

  &_image {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }

  &_action {
    @apply absolute hidden m-0;
    
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  &:hover {
    .qi-photo-uploader_action {
      @apply flex items-center justify-center;

      .upload-button {
        color: #fff;
      }
    }
  }

  .upload-button {
    @apply text-center text-white cursor-pointer bg-green-400;
  }
}
